<template>
    <div>
        <div>
            <hb-form label="Default" description="These categories are created by default" full>
                <hb-chip v-for="(item, i) in categories" :key="'editable_chip_' + item.id" pointer>
                    {{ item.notes_category }}
                    <template>
                        {{ '(' + item.count + ')' }}
                    </template>
                </hb-chip>
            </hb-form>
            <hb-form label="Custom" description="Create custom categories to organize your notes" full>
                <div class="d-flex flex-row" v-if="!isPropertySelected">
                        <HbTextField class="setting-note-input" box v-model="categoryName" 
                        v-validate="{ required: true, regex:/^[a-zA-Z]+(?:\s[a-zA-Z]+)?$/ }"
                        id="category_name"
                        name="category_name"
                        data-vv-scope="general"
                        data-vv-as="Category Name"
                        :error="errors.collect('general.category_name').length > 0"
                        placeholder="Category Name" />
                        <hb-btn class="ml-5" @click="createNoteCategory()" color="secondary" medium :disabled="disablePermissionToCreate">Create</hb-btn>
                    </div>
                <HbTextField full readonly>
                    <template v-slot:prepend-inner>
                        <div class= "chip-block">
                            <hb-chip v-for="(item, i) in customCata" :key="'editable_chip_' + i"
                                @click="handleChipCloseClick(item)" @chip-click="noteChipClick(item)" pointer  :editable=permissionToDelete>
                                {{ item.notes_category }}
                                <template>
                                    {{ '(' + item.count + ')' }}
                                </template>
                            </hb-chip>
                        </div>
                    </template>
                </HbTextField>
            </hb-form>
            <!-- <HbBottomActionBar cancelOff>
            </HbBottomActionBar> -->
        </div>
        <hb-modal size="medium" title="Delete Note Category" v-model="deleteModal" confirmation>
            <template v-slot:content>
                <div class="py-4 px-6">
                    <span v-if="isNotes">
                        This category is currently linked to specific notes. Deleting this category will reassign associated notes to the 'Miscellaneous' category
                    </span>
                    <span v-else>
                        Are you sure you want to delete this category?
                        <br /><br />
                        This action cannot be undone.
                    </span>
                </div>
            </template>
            <template v-slot:left-actions>
            </template>
            <template v-slot:right-actions>
                <hb-btn color="destructive" @click="deleteCataconfirmation()">Delete</hb-btn>
            </template>
        </hb-modal>
    </div>
</template>
<script>
import api from "../../../assets/api.js";
import { mapGetters } from "vuex";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { HbChip } from 'hummingbird-aviary';

export default {
    name: "NotesCategories",
    props: [],
    data() {
        return {
            isNotes: '', // make it true if note Category contains any notes
            deleteModal: false,
            active: 'Default',
            categoryName: '',
            categories: [],
            customCata: [],
            miscId: '',
            deletingId: '',
            isUpdate: false,
            disablePermissionToCreate: true,
            permissionToDelete: false

        };
    },
    props: {
        level: {
            type: String,
            default: "",
        },
        facilityID: {
            type: String,
            default: ""
        }
    },
    mixins: [notificationMixin],
    computed: {
        isPropertySelected() {
            return this.facilityID ? true : false;
        },
    },
    watch: {
        facilityID: {
            handler: 'getCatagories',
            immediate: true
        },
    },
    async created() {
    let userData= JSON.parse(localStorage.getItem('hb_user_details'));
    let permission = userData.permissions.findIndex(e => e['label'] === 'create_custom_notes_category');
    let checkPermissionToDelete = userData.permissions.findIndex(e => e['label'] === 'delete_custom_notes_category');
    this.disablePermissionToCreate = permission != -1? false : true ;
    this.permissionToDelete = checkPermissionToDelete != -1? true : false;
   },
    async mounted() {
        await this.getCatagories()
    },
    methods: {
        async getCatagories() {
            let resp;
            if (this.isPropertySelected) {
                resp = await api.get(this, api.NOTES_CATEGORIES_SETTINGS + `?property_id=${this.facilityID}`);
            } else {
                resp = await api.get(this, api.NOTES_CATEGORIES_SETTINGS);
            }
            this.categories = resp.default_notes_catagories;
            const miscellaneousCategory = this.categories.find(category => category.notes_category === "Miscellaneous");
            this.miscId = miscellaneousCategory ? miscellaneousCategory.id : null;
            this.customCata = resp.custom_notes_catagories;
        },
        async createNoteCategory() {
            let general_status = await this.$validator.validateAll('general');
            if (!general_status) {
                  this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                  return;
                }
            if (this.categoryName) {
                let path = '/custom'
                let data = {
                    notes_category: this.categoryName
                }
                try {
                    let resp = await api.post(this, api.NOTES_CATEGORIES + path, data)
                    console.log('resp', resp);
                    this.getCatagories()
                    this.categoryName = ''
                } catch (e) {
                    console.log("API FAIL", e)
                    this.showMessageNotification({ type: 'error', description: e });
                }
            }
        },
        handleChipCloseClick(item) {
            this.deleteModal = true // open modal
            if (item.count !== 0) {
                this.isNotes = true  // note is there 
            }
            else {
                this.isNotes = false
            }
            this.deletingId = item.id
        },
        async deleteCataconfirmation() {
            const path = '/custom/delete-transfer';
            const data = this.isPropertySelected
                ? {
                    property_id: this.facilityID,
                    delete_notes_category_id: this.deletingId,
                    transfer_notes_category_id: this.miscId
                }
                : {
                    delete_notes_category_id: this.deletingId,
                    transfer_notes_category_id: this.miscId
                };

            try {
                await api.post(this, api.NOTES_CATEGORIES + path, data);
                this.getCatagories();
                this.deleteModal = false;
            } catch (error) {
                console.error("API request failed:", error);
            }
        },
    }
}
</script>
<style scoped>
.setting-note-input {
    min-width: 350px;
}

.chips-container {
    display: flex;
    flex-wrap: wrap;
}

.chip-block {
    display: inline-block;
    margin: 5px;
    /* Adjust this to control spacing between chips */
}

.v-text-field__slot {
    display: none !important;
}
</style>