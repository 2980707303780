export default {
    watch: {
        level: {
            immediate: true,
            handler() {
                this.initializeData()
                if (this.level === "Corporate") {
                    this.property_id = "";
                    this.fetchData();
                }
            },
        },
    }
};