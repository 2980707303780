<template>
  <div>
    <div v-show="showLoader" class="loader-position">
      <span>
        <loader color="#00b2ce" size="50px" class="inline-loader"></loader>
      </span>
    </div>
    <div v-show="!showLoader">
      <hb-form
        label="Email"
        description="Set custom hours you desire emails should not be sent to your tenants."
      >
        <template v-slot:tooltipHeader> Tool tip </template>
        <template v-slot:tooltipBody>
          email communication will be disable for selected hours
        </template>
        <v-row class="mb-0" no-gutters no-wrap>
          <v-col v-if="noRecordsFound">
            <hb-link @click="noRecordsFound = false">
              + Add Do Not Disturb Hours
            </hb-link>
          </v-col>
          <v-col class="pr-2" cols="3" v-if="!noRecordsFound">
            <div class="hb-inline-sentence-text mb-0">Start Hour</div>
            <div class="hb-inline-sentence-text mb-0">End Hour</div>
          </v-col>
          <v-col v-if="!noRecordsFound" cols="4">
            <HbSelect condensed box width="110px" v-validate="'required'" :items="timeOptions" data-vv-scope="default"
              id="type" name="type" v-model="getEmailStartTime" :placeholder="'Select Time'" />
            <HbSelect box condensed width="110px" v-validate="'required'" :items="timeOptions" data-vv-scope="default"
              id="type" name="type" v-model="getEmailEndTime" :placeholder="'Select Time'"/>
          </v-col>
          <!-- <v-col v-if="!noRecordsFound" style="white-space: nowrap;" class="text-center" cols="4">
              <div class="hb-inline-sentence-text ">Communication resumes in : 
                <strong> 
                  {{ emailBlackoutDuration }}
                </strong>
              </div>
              <div class="hb-inline-sentence-text mb-0 hb-text-light ">Last Modified By {{ responseData?.firstName }} {{ responseData?.lastName }} @ {{ responseData?.created_on | formatLocalDateTime }}
              </div>
            </v-col> -->
        </v-row>
        <div v-if="!noRecordsFound" class="hb-inline-sentence-text mt-n2 pa-0" style="white-space: nowrap;">Communication resumes in :
          <strong>
            {{ emailBlackoutDuration }}
          </strong>
        </div>
        <!-- <div v-if="!noRecordsFound" class="hb-inline-sentence-text mt-n2 pa-0 hb-text-light" style="white-space: nowrap;">Last Modified By {{ responseData?.firstName }} {{ responseData?.lastName }} @ {{ responseData?.updated_on | formatLocalDateTime }}
              </div> -->
        <v-row no-gutters class="ma-0 pa-0">
          <v-col>
         
        </v-col>
        </v-row>
        <v-row>
          <v-col align="start" class="mr-4" v-if="!noRecordsFound">
            <hb-btn color="primary" condensed class="mr-4" @click="save('email')"
              :disabled="setButtonControl()">Save</hb-btn>
            <hb-btn color="secondary" @click="cancel('email')">Reset</hb-btn>
          </v-col>
        </v-row>
      </hb-form>
      <hb-form label="Text" description="Set custom hours you desire texts should not be sent to your tenants.">
        <template v-slot:tooltipHeader> Tool tip </template>
        <template v-slot:tooltipBody>
          text communication will be disable for selected hours
        </template>
        <v-row class="mb-0" no-gutters no-wrap>
          <v-col v-if="noRecordsFound">
            <hb-link @click="noRecordsFound = false">
              + Add Do Not Disturb Hours
            </hb-link>
          </v-col>
          <v-col class="pr-2 col-3" v-if="!noRecordsFound">
            <div class="hb-inline-sentence-text mb-0">Start Hour</div>
            <div class="hb-inline-sentence-text mb-0">End Hour</div>
          </v-col>
          <v-col v-if="!noRecordsFound" cols="4">
            <HbSelect condensed box width="110px" v-validate="'required'" :items="timeOptions" data-vv-scope="default"
              id="type" name="type" v-model="getTextStartTime" :placeholder="'Select Time'"/>
            <HbSelect box condensed width="110px" v-validate="'required'" :items="timeOptions" data-vv-scope="default"
              id="type" name="type" v-model="getTextEndTime" :placeholder="'Select Time'"/>
          </v-col>
          <!-- <v-col v-if="!noRecordsFound" style="white-space: nowrap;" class="text-center" cols="4">
              <div class="hb-inline-sentence-text mb-0">Communication resumes in : 
                <strong>
                  {{ textBlackoutDuration }}
                </strong>
              </div>
              <div class="hb-inline-sentence-text mb-0 hb-text-light">Last Modified By {{ responseData?.firstName }} {{ responseData?.lastName }} @ {{ responseData?.created_on | formatLocalDateTime }}
              </div>
            </v-col> -->
        </v-row>
        <div v-if="!noRecordsFound" class="hb-inline-sentence-text mt-n2 pa-0" style="white-space: nowrap;">
          Communication resumes in :
          <strong>
            {{ textBlackoutDuration }}
          </strong>
        </div>
        <!-- <div v-if="!noRecordsFound" class="hb-inline-sentence-text mt-n2 pa-0 hb-text-light" style="white-space: nowrap;">Last Modified By {{ responseData?.firstName }} {{ responseData?.lastName }} @ {{ responseData?.updated_on | formatLocalDateTime }}
              </div> -->
        <v-row>
          <v-col align="start" class="mr-4" v-if="!noRecordsFound">
            <hb-btn color="primary" condensed class="mr-4" @click="save('text')"
              :disabled="setButtonControl()">Save</hb-btn>
            <hb-btn color="secondary" @click="cancel('text')">Reset</hb-btn>
          </v-col>
        </v-row>
      </hb-form>
      <v-row class="mb-0" no-gutters no-wrap>
        <div class="pr-2" cols="3" >
        <div v-if="!noRecordsFound" class="hb-inline-sentence-text pa-1 hb-text-light" style="white-space: nowrap;">Last Modified By {{ responseData?.firstName }} {{ responseData?.lastName }} @ {{ responseData?.updated_on | formatLocalDateTime }}
        </div>
        <div v-if="!noRecordsFound && !this.owner_id"  class="hb-inline-sentence-text pa-1 hb-text-light" style="white-space: nowrap;">UTC Offset for this property is :
          <strong>
            {{ utcOffset }}
          </strong>
        </div>
        <div v-if="!noRecordsFound && !this.owner_id"  class="hb-inline-sentence-text pa-1 hb-text-light" style="white-space: nowrap;">Property time zone is :
          <strong>
            {{ propertyTimeZone }}
          </strong>
        </div>
      </div>
      </v-row>
    </div>
  </div>
</template>
<script>
import api from "../../../assets/api.js";
import { mapGetters } from "vuex";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { EventBus } from "../../../EventBus";
import Loader from "../../assets/CircleSpinner.vue";
export default {
  name: "emailSmsDND",
  props: ["gds_facility_id", "owner_id", "level", "headerToggle"],
  data() {
    return {
      timeOptions: ["12:00 AM", "1:00 AM", "2:00 AM", "3:00 AM", "4:00 AM", "5:00 AM", "6:00 AM", "7:00 AM", "8:00 AM", "9:00 AM", "10:00 AM", "11:00 AM", "12:00 PM", "1:00 PM", "2:00 PM", "3:00 PM", "4:00 PM", "5:00 PM", "6:00 PM", "7:00 PM", "8:00 PM", "9:00 PM", "10:00 PM", "11:00 PM",],
      getEmailStartTime: "",
      utcOffset:"",
      propertyTimeZone:"",
      getEmailEndTime: "",
      getTextStartTime: "",
      getTextEndTime: "",
      setEmailStartTime: "",
      setEmailEndTime: "",
      setTextStartTime: "",
      setTextEndTime: "",
      levels: ["Corporate", "Property"],
      defaultTime: "",
      responseData: "",
      textBlackoutDuration: "",
      emailBlackoutDuration: "",
      noRecordsFound: true,
      isDefaultTimeSet: true,
      disablePermissionToChange: true,
      created_on: "",
      updated_on: "",
      showLoader: true,
    };
  },
  components: { Loader },
  mixins: [notificationMixin],
  computed: {
    ...mapGetters({
      loggedInUser: "authenticationStore/getUserData",
      // hasPermission: 'authenticationStore/rolePermission',
    }),
  },
  watch: {
    headerToggle() {
      if (!this.isDefaultTimeSet) {
        this.save("email_and_text")
      };
    },
    level() {},
    gds_facility_id() {
      this.fetchDefaultTime();
    },
    owner_id() {
      this.fetchDefaultTime();
    },
    getEmailStartTime() {
      if (this.getEmailEndTime && this.getEmailStartTime) {
        this.validateTime("email");
      }
    },
    getEmailEndTime() {
      if (this.getEmailStartTime && this.getEmailEndTime) {
        this.validateTime("email");
      }
    },
    getTextStartTime() {
      if (this.getTextStartTime && this.getTextEndTime)
        this.validateTime("text");
    },
    getTextEndTime() {
      if (this.getTextStartTime && this.getTextEndTime)
        this.validateTime("text");
    },
    setEmailEndTime() {
      console.log(this.setEmailEndTime, "this.setEmailEndTime");
    },
    responseData() {
      if (this.responseData?.email_blackout_end_time) {
        this.noRecordsFound = false;
      } else {
        this.noRecordsFound = true;
      }
      this.setDefaultTime()
      EventBus.$emit('toggle-status', this.responseData?.use_corporate_default_settings ?? false)
    }
  },
  async created() {
    let userData= JSON.parse(localStorage.getItem('hb_user_details'));
    let permission = userData.permissions.findIndex(e => e['label'] === 'manage_email/sms_do_not_disturb_functionality');
    this.disablePermissionToChange = permission != -1? false : true ;
  },
  async mounted() {
    this.fetchDefaultTime();
  },
  methods: {
    async save(type) {
      if(this.validateRequestData(type)){
        this.showMessageNotification({
            type: "error",
            description: `Please select the Start and End Time for ${type}`,
          });
        return;
      }
      try {
        let data = {
          use_corporate_default_settings: this.headerToggle ? true : false,
          contactId: this.loggedInUser.id,
        };

        if (type === "email") {
          data.email_blackout_start_time = this.convertTimeTo24HourFormat(this.getEmailStartTime);
          data.email_blackout_end_time = this.convertTimeTo24HourFormat(this.getEmailEndTime);
          data.sms_blackout_start_time = this.setTextStartTime ? this.convertTimeTo24HourFormat(this.setTextStartTime):null;
          data.sms_blackout_end_time = this.setTextEndTime ? this.convertTimeTo24HourFormat(this.setTextEndTime):null;
        } else {
          data.sms_blackout_start_time = this.convertTimeTo24HourFormat(this.getTextStartTime);
          data.sms_blackout_end_time = this.convertTimeTo24HourFormat(this.getTextEndTime);
          data.email_blackout_start_time = this.getEmailStartTime?this.convertTimeTo24HourFormat(this.getEmailStartTime):null;
          data.email_blackout_end_time = this.getEmailEndTime?this.convertTimeTo24HourFormat(this.getEmailEndTime):null;
        }
        if (this.owner_id) data.ownerId = this.owner_id;
        if (this.gds_facility_id) data.facilityId = this.gds_facility_id;
        if(!this.responseData?.created_on?.length){
          data.created_on = new Date().toISOString().replace("T", " ").replace("Z", "")
        }else{
          data.created_on = this.responseData?.created_on
        }
        data.updated_on = new Date().toISOString().replace("T", " ").replace("Z", "")
        await api.post(this, api.BLACKOUT, data);
        if (type != "email_and_text") {
          this.showMessageNotification({
            type: "success",
            description: `Do Not Disturb time is set success  fully for ${type}`,
          });
        }
        await this.fetchDefaultTime();
      } catch (error) {
        console.log(error);
        if (type != "email_and_text") {
          this.showMessageNotification({
            type: "error",
            description: `Something went wrong!! while setting up Do Not Disturb time`,
          });
        }
      }
    },
    cancel(type) {
      if (type === "email") {
        this.getEmailEndTime = this.responseData?.email_blackout_end_time? this.convertTimeToAMPM(this.responseData.email_blackout_end_time):null;
        this.getEmailStartTime = this.responseData?.email_blackout_start_time?this.convertTimeToAMPM(this.responseData.email_blackout_start_time):null;
      } else {
        this.getTextEndTime = this.responseData?.sms_blackout_end_time?this.convertTimeToAMPM(this.responseData.sms_blackout_end_time):null;
        this.getTextStartTime = this.responseData?.sms_blackout_start_time?this.convertTimeToAMPM(this.responseData.sms_blackout_start_time):null;
      }

      this.showMessageNotification({
        type: "success",
        description: "Do Not Disturb time has been reset for " + type,
      });
    },
    async fetchDefaultTime() {
      this.showLoader = true;
      try {
        //for company level
        if (this.owner_id) {
          this.responseData = await api.get(this, api.BLACKOUT + "?owner_id=" + this.owner_id);
        }
        //for property level
        if (this.gds_facility_id) {
          this.responseData = await api.get(this, api.BLACKOUT + "?gds_facility_id=" + this.gds_facility_id);
        }
      } catch (error) {
        console.log("get api failed");
        this.setDefaultTime();
      } finally {
        this.showLoader = false;
      }
    },
    setButtonControl() {
      console.log('==============================',this.disablePermissionToChange,this.level,this.gds_facility_id, this.headerToggle);
      if (!this.disablePermissionToChange) {
        if (this.level == this.levels[0]) {
          // corporate level
          if (!this.owner_id) return true;
          else return false;
        }
        if (this.level == this.levels[1]) {
          // property level
          if (!this.gds_facility_id || this.headerToggle) return true;
          else return false;
        }
      } else {
        return true;
      }
    },
    setDefaultTime() {
      this.utcOffset=this.responseData.timezone
      this.propertyTimeZone=this.responseData.facility_timezone
      if (this.responseData?.email_blackout_start_time) {
        this.setEmailEndTime = this.getEmailEndTime = this.convertTimeToAMPM(this.responseData.email_blackout_end_time);
        this.setEmailStartTime = this.getEmailStartTime = this.convertTimeToAMPM(this.responseData.email_blackout_start_time);
        this.setTextEndTime = this.getTextEndTime = this.convertTimeToAMPM(this.responseData.sms_blackout_end_time);
        this.setTextStartTime = this.getTextStartTime = this.convertTimeToAMPM(this.responseData.sms_blackout_start_time);
        this.isDefaultTimeSet = false
      }
      else {
        this.isDefaultTimeSet = true
        this.getEmailEndTime = this.getTextEndTime = null
        this.getTextStartTime = this.getEmailStartTime = null
        this.setTextStartTime = this.setEmailStartTime = null
        this.setEmailEndTime = this.setTextEndTime = null
      }
    },
    convertTimeTo24HourFormat(time) {
      try {
        const [hour, minute, period] = time.split(/:| /);
        let convertedHour = parseInt(hour, 10);

        if (period === "PM" && convertedHour !== 12) {
          convertedHour += 12;
        } else if (period === "AM" && convertedHour === 12) {
          convertedHour = 0;
        }
        return `${convertedHour.toString().padStart(2, "0")}:${minute}`;
      } catch (error) {
        throw new Error("conversion error");
      }
    },
    convertTimeToAMPM(time) {
      const [hours, minutes, seconds] = time.split(":");
      let period = "AM";
      let formattedHours = parseInt(hours);

      if (formattedHours >= 12) {
        period = "PM";
        formattedHours -= 12;
      }

      if (formattedHours === 0) {
        formattedHours = 12;
      }

      return `${formattedHours}:${minutes} ${period}`;
    },
    formatDefaultTime(time) {
      const [hours, minutes, seconds] = time.split(":");
      return `${hours}:${minutes}`;
    },
    validateTime(type) {
      if (type == "email") {
        this.emailBlackoutDuration = ` ${this.getTimeDifference(
          this.getEmailStartTime,
          this.getEmailEndTime
        )}`;
      } else {
        this.textBlackoutDuration = ` ${this.getTimeDifference(
          this.getTextStartTime,
          this.getTextEndTime
        )}`;
      }
    },
    getTimeDifference(startTime, endTime) {
      // Convert input times to 24-hour format
      const start = this.convertTimeTo24HourFormat(startTime);
      const end = this.convertTimeTo24HourFormat(endTime);

      // Calculate the time difference in minutes
      const startMinutes = this.convertToMinutes(start);
      const endMinutes = this.convertToMinutes(end);
      let difference = endMinutes - startMinutes;

      // If the difference is negative or zero, add 24 hours (1440 minutes) to make it positive
      if (difference <= 0) {
        difference += 1440;
      }

      // Convert the difference back to hours and minutes
      const hours = Math.floor(difference / 60);
      const minutes = difference % 60;

      // Return the time difference as a string
      return `${hours} hours ${minutes} minutes`;
    },
    convertToMinutes(time) {
      const [hours, minutes] = time.split(":");
      return parseInt(hours) * 60 + parseInt(minutes);
    },
    validateRequestData(type){
      if(type == 'email'){
        return this.getEmailStartTime === null || this.getEmailEndTime === null 
      }
      else{
        return this.getTextStartTime === null || this.getTextEndTime === null 

      }
    }
  },
};
</script>
<style scoped>
.loader-position {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
