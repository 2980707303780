import { render, staticRenderFns } from "./NoteCategories.vue?vue&type=template&id=460b74f0&scoped=true&"
import script from "./NoteCategories.vue?vue&type=script&lang=js&"
export * from "./NoteCategories.vue?vue&type=script&lang=js&"
import style0 from "./NoteCategories.vue?vue&type=style&index=0&id=460b74f0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "460b74f0",
  null
  
)

export default component.exports